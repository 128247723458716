import { render, staticRenderFns } from "./CollectionMenuNew.vue?vue&type=template&id=aabd9808&scoped=true&"
import script from "./CollectionMenuNew.vue?vue&type=script&lang=ts&"
export * from "./CollectionMenuNew.vue?vue&type=script&lang=ts&"
import style0 from "./CollectionMenuNew.vue?vue&type=style&index=0&id=aabd9808&lang=less&scoped=true&"
import style1 from "./CollectionMenuNew.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aabd9808",
  null
  
)

export default component.exports